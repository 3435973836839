@import "./../../assets/styles/variables";

.thanosV1--container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px;

    input {
        border: 1px black solid;
        border-radius: 0;
    }


    table {
        border-collapse: collapse;
        width: 100%;

        th, td {
            border: 1px black solid;
            padding: 4px;
        }

        th {
            background-color: lightslategray;
            color: $color-dark-font;
        }

        td {
            background-color: $color-light;
            color: $color-light-font;

            input {
                background-color:lightblue;
                width: 95%;
                height: 95%;
            }
        }
    }

    button {
        background-color: $color-dark;
        color: $color-dark-font;
        border: 1px black solid;
        border-radius: 0;
        padding: 10px;
        margin-top: 10px;
    }
    

    select {
        border: 1px black solid;
        border-radius: 0;
    }

    .theme-dark {
        input {
            background-color: $color-dark;
            color: $color-dark-font;
        }
    }

    .theme-light {
        input {
            background-color: $color-light;
            color: $color-light-font;
        }
    }
}


