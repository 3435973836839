@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/mixins";

.quoteedit__task {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    user-select: none;
    transition: $transition-mode;
    align-items: center;
    justify-content: center;
    font-size: rem(16px);
    gap: 0.3em;

    &:hover {
        background-color: rgba(250, 250, 250, 0.7);
        .body__exapand-delete {
            display: unset;
            opacity: 0.5;
        }
        .body__exapand-copy {
            display: unset;
            opacity: 0.5;
        }
    }

    &:hover,
    &:active {
        background-color: rgba(240, 240, 240, 0.25);
    }

    &:active {
        opacity: 0.5;
    }

    .task__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex: 1;
        gap: 0.7em;
        height: fit-content;
    }

    .task__body {
        display: flex;
        flex: 1;
        gap: 0.5em;
        width: 100%;
        justify-content: space-between;
        border-bottom: $color-light-elements 1px solid;
        border-right: $color-light-elements 1px solid;
        border-radius: 0 0 5px 0;
        margin-bottom: 0.8em;
    }

    .tstext__body {
        display: flex;
        flex: 1;
        gap: 0.5em;
        width: 100%;
        justify-content: space-between;
        border-bottom: $color-light-elements 1px solid;
        border-right: $color-light-elements 1px solid;
        border-radius: 0 0 5px 0;
        margin-bottom: 0.8em;
    }

    .task__item {
        flex: 1;
        padding: 0.5em;
        background-color: $color-light-elements;
        width: 100%;
    }

    .btn__task__startstop {
        background-Color: "gray";
        color: "white";
        padding: "2px 2px";
        border: "none";
        border-Radius: "5px";
        font-Size: "24px";
    }

    .task__id {
        flex: 0 0 2em;
        padding: 0.6em 0em;
        font-size: rem(14px);
    }

    .task__tsId {
        flex: 0 0 8em;
        padding: 0.6em 0em;
        font-size: rem(10px);
    }

    .task__title {
        flex: 1;
        width: 100%;
        text-align: left;
        position: relative;
        background-color: $color-light-elements;

        input {
            font-family: $font-family;
            letter-spacing: -0.02em;
            width: 100%;
            background-color: $color-light-elements;
        }

        .has-info {
            position: absolute;
            right: 0.7em;
            top: 0.5em;
            color: $color-red;
            background-color: $color-light-elements;
        }

        .has-info-text {
            display: none;
            font-size: rem(14px);
            position: absolute;
            left: 0;
            top: 0.5em;
            z-index: 100;
            background-color: rgba(255, 255, 255, 0.98);
            padding: 1em;
            color: black;
            min-width: 30vw;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }

        .has-info:hover {
            .has-info-text {
                display: unset;
            }
        }
    }

    .btn__startstop{
        flex: 1;
        width: 30%;
        text-align: left;
        position: relative;
        background-color: $color-light-elements;
    }

    .task__expand {
        flex: 1;
        width: 30%;
        text-align: left;
        position: relative;
        background-color: $color-light-elements;

        input {
            font-family: $font-family;
            letter-spacing: -0.02em;
            width: 30%;
            background-color: $color-light-elements;
        }

        .has-info {
            position: absolute;
            right: 0.7em;
            top: 0.5em;
            color: $color-red;
            background-color: $color-light-elements;
        }

        .has-info-text {
            display: none;
            font-size: rem(14px);
            position: absolute;
            left: 0;
            top: 0.5em;
            z-index: 100;
            background-color: rgba(255, 255, 255, 0.98);
            padding: 1em;
            color: black;
            min-width: 30vw;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }

        .has-info:hover {
            .has-info-text {
                display: unset;
            }
        }
    }

    .task__duration {
        text-align: right;
        flex: 0 0 5em;
    }

    .task__unit {
        text-align: left;
        flex: 0 0 5em;
        padding: 0.5em;
        width: 100%;
    }

    .task__workgroup {
        text-align: left;
        flex: 0 0 15em;
    }

    .task__hourly-rate {
        text-align: left;
        flex: 0 0 5em;
    }

    .task__total {
        text-align: right;
        flex: 0 0 7em;
        padding: 0.5em 0;
        font-size: rem(16px);
        width: 100%;
    }

    .body__exapand-less {
        padding: 0.5em;
        cursor: pointer;
        flex: 0 0 5em;
    }

    .body__tbtextbtn {
        padding: 0.5em;
        cursor: pointer;
        flex: 0 0 5em;
    }


    .body__exapand-copy,
    .body__exapand-delete {
        height: 100%;
        display: none;
        position: absolute;
        padding: 0.5em;
        opacity: 0.5;
        cursor: pointer;
        flex: 0 0 5em;
    }

    .body__exapand-copy {
        right: 100%;
    }
    .body__exapand-delete {
        color: $color-red;
        left: 100%;
    }
    .body__exapand-copy:hover,
    .body__exapand-delete:hover {
        opacity: 1.5;
    }

    .body__exapand-date {
        padding: 0.5em;
        cursor: pointer;
        flex: 0 0 12.5em;
    }

    textarea {
        height: 5em;
        outline: 0;
        border: none;
        background-color: $color-light-elements;
        flex: 1;
    }
}

.offerlist--phase-minimized {
    .quoteedit__task {
        display: none;
    }
}
